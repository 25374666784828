/**
 * adapted from http://stemkoski.github.io/Three.js/Sprite-Text-Labels.html
 */

export class TextSprite extends THREE.Object3D {
  constructor(text) {
    super();

    let texture = new THREE.Texture();
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    let spriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      depthTest: false,
      depthWrite: false,
    });

    this.material = spriteMaterial;
    this.sprite = new THREE.Sprite(spriteMaterial);
    this.add(this.sprite);

    this.borderThickness = 0;
    this.fontface = "Arial, sans-serif";
    this.fontsize = 20;
    this.borderColor = { r: 0, g: 0, b: 0, a: 1.0 };
    this.backgroundColor = { r: 255, g: 255, b: 255, a: 1.0 };
    this.textColor = { r: 255, g: 255, b: 255, a: 1.0 };
    this.text = "";

    this.setText(text);
  }

  setText(text) {
    if (this.text !== text) {
      this.text = text;

      this.update();
    }
  }

  setTextColor(color) {
    this.textColor = color;

    this.update();
  }

  setBorderColor(color) {
    this.borderColor = color;

    this.update();
  }

  setBackgroundColor(color) {
    this.backgroundColor = color;

    this.update();
  }

  update() {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");

    context.scale(2, 2);

    context.font = this.fontsize + "px " + this.fontface;

    // get size data (height depends only on font size)
    let metrics = context.measureText(this.text);
    let textWidth = metrics.width;
    let margin = 20;
    let spriteWidth = 2 * margin + textWidth + 2 * this.borderThickness;
    let spriteHeight = this.fontsize * 1.4 + margin;

    context.canvas.width = spriteWidth;
    context.canvas.height = spriteHeight;
    context.font = this.fontsize + "px " + this.fontface;

    // background color
    context.fillStyle = "rgba(0, 0, 0, .7)";
    // border color
    // context.strokeStyle = 'rgba(' + this.borderColor.r + ',' + this.borderColor.g + ',' +
    // 	this.borderColor.b + ',' + this.borderColor.a + ')';

    // context.lineWidth = this.borderThickness;
    this.roundRect(
      context,
      this.borderThickness / 2,
      this.borderThickness / 2,
      textWidth + this.borderThickness + 2 * margin,
      spriteHeight,
      0
    );

    // text color
    // context.strokeStyle = 'rgba(0, 0, 0, 1.0)';
    // context.strokeText(this.text, this.borderThickness + margin, this.fontsize + this.borderThickness);

    context.fillStyle =
      "rgba(" +
      this.textColor.r +
      "," +
      this.textColor.g +
      "," +
      this.textColor.b +
      "," +
      this.textColor.a +
      ")";
    context.fillText(
      this.text,
      this.borderThickness + margin,
      this.fontsize + margin / 2
    );

    let texture = new THREE.Texture(canvas);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.needsUpdate = true;

    this.sprite.material.map = texture;

    this.sprite.scale.set(spriteWidth * 0.01, spriteHeight * 0.01, 1.0);
  }

  roundRect(ctx, x, y, w, h, r) {
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + r);
    ctx.lineTo(x + w, y + h - r);
    ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    ctx.lineTo(x + r, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
    ctx.closePath();
    ctx.fill();
  }
}
