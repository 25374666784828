const STANDBY_OPACITY = 0.2;
const DEFAULT_OPACITY = 0.5;
const HOVERED_OPACITY = 0.8;
const MAIN_COLOR = 0x2f9eed;
const OUTLINE_COLOR = 0x252427;

export class NavigationCube extends THREE.Object3D {
  constructor(viewer) {
    super();

    this.viewer = viewer;
    this.outlineGroup = new THREE.Group();
    this.facesGroup = new THREE.Group();
    const cubeSize = 0.8;
    const faces = new faceFactory(cubeSize, new THREE.Color(MAIN_COLOR));

    // ViewCube Faces
    const f_F = faces.genFace(
      new THREE.Vector3(0, -0.5, 0),
      new THREE.Vector3((90 * Math.PI) / 180, 0, 0),
      "F.png"
    );
    f_F.name = "F";
    this.facesGroup.add(f_F);

    const f_B = faces.genFace(
      new THREE.Vector3(0, 0.5, 0),
      new THREE.Vector3((-90 * Math.PI) / 180, 0, 0),
      "B.png"
    );
    f_B.name = "B";
    this.facesGroup.add(f_B);

    const f_R = faces.genFace(
      new THREE.Vector3(0.5, 0, 0),
      new THREE.Vector3(0, (90 * Math.PI) / 180, 0),
      "R.png"
    );
    f_R.name = "R";
    this.facesGroup.add(f_R);

    const f_L = faces.genFace(
      new THREE.Vector3(-0.5, 0, 0),
      new THREE.Vector3(0, (-90 * Math.PI) / 180, 0),
      "L.png"
    );
    f_L.name = "L";
    this.facesGroup.add(f_L);

    const f_U = faces.genFace(
      new THREE.Vector3(0, 0, 0.5),
      new THREE.Vector3(0, 0, 0),
      "U.png"
    );
    f_U.name = "U";
    this.facesGroup.add(f_U);

    const f_D = faces.genFace(
      new THREE.Vector3(0, 0, -0.5),
      new THREE.Vector3(0, (-180 * Math.PI) / 180, 0),
      "D.png"
    );
    f_D.name = "D";
    this.facesGroup.add(f_D);

    // ViewCube Corners
    const c_URB = faces.genCorner(
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(0.35, 0.35, 0.5)
    );
    c_URB.name = "URB";
    this.facesGroup.add(c_URB);

    const c_ULB = faces.genCorner(
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(-0.35, 0.5, 0.35),
      new THREE.Vector3(-0.5, 0.35, 0.35)
    );
    c_ULB.name = "ULB";
    this.facesGroup.add(c_ULB);

    const c_DRB = faces.genCorner(
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(0.35, 0.5, -0.35),
      new THREE.Vector3(0.5, 0.35, -0.35)
    );
    c_DRB.name = "DRB";
    this.facesGroup.add(c_DRB);

    const c_DLB = faces.genCorner(
      new THREE.Vector3(-0.5, 0.35, -0.35),
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(-0.35, 0.35, -0.5)
    );
    c_DLB.name = "DLB";
    this.facesGroup.add(c_DLB);

    const c_URF = faces.genCorner(
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(0.35, -0.5, 0.35),
      new THREE.Vector3(0.5, -0.35, 0.35)
    );
    c_URF.name = "URF";
    this.facesGroup.add(c_URF);

    const c_ULF = faces.genCorner(
      new THREE.Vector3(-0.5, -0.35, 0.35),
      new THREE.Vector3(-0.35, -0.5, 0.35),
      new THREE.Vector3(-0.35, -0.35, 0.5)
    );
    c_ULF.name = "ULF";
    this.facesGroup.add(c_ULF);

    const c_DRF = faces.genCorner(
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(0.35, -0.35, -0.5)
    );
    c_DRF.name = "DRF";
    this.facesGroup.add(c_DRF);

    const c_DLF = faces.genCorner(
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(-0.35, -0.5, -0.35),
      new THREE.Vector3(-0.5, -0.35, -0.35)
    );
    c_DLF.name = "DLF";
    this.facesGroup.add(c_DLF);

    // ViewCube Edges
    // Top
    const e_UR = faces.genEdge(
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(0.5, -0.35, 0.35),
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.35, 0.35, 0.5)
    );
    e_UR.name = "UR";
    this.facesGroup.add(e_UR);

    const e_UL = faces.genEdge(
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(-0.5, 0.35, 0.35),
      new THREE.Vector3(-0.5, -0.35, 0.35),
      new THREE.Vector3(-0.35, -0.35, 0.5)
    );
    e_UL.name = "UL";
    this.facesGroup.add(e_UL);

    const e_UF = faces.genEdge(
      new THREE.Vector3(-0.35, -0.5, 0.35),
      new THREE.Vector3(0.35, -0.5, 0.35),
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(-0.35, -0.35, 0.5)
    );
    e_UF.name = "UF";
    this.facesGroup.add(e_UF);

    const e_UB = faces.genEdge(
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(0.35, 0.35, 0.5),
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(-0.35, 0.5, 0.35)
    );
    e_UB.name = "UB";
    this.facesGroup.add(e_UB);

    // Mid
    const e_RB = faces.genEdge(
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.5, 0.35, -0.35),
      new THREE.Vector3(0.35, 0.5, -0.35)
    );
    e_RB.name = "RB";
    this.facesGroup.add(e_RB);

    const e_LB = faces.genEdge(
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(-0.5, 0.35, -0.35),
      new THREE.Vector3(-0.5, 0.35, 0.35),
      new THREE.Vector3(-0.35, 0.5, 0.35)
    );
    e_LB.name = "LB";
    this.facesGroup.add(e_LB);

    const e_RF = faces.genEdge(
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.5, -0.35, 0.35),
      new THREE.Vector3(0.35, -0.5, 0.35)
    );
    e_RF.name = "RF";
    this.facesGroup.add(e_RF);

    const e_LF = faces.genEdge(
      new THREE.Vector3(-0.35, -0.5, 0.35),
      new THREE.Vector3(-0.5, -0.35, 0.35),
      new THREE.Vector3(-0.5, -0.35, -0.35),
      new THREE.Vector3(-0.35, -0.5, -0.35)
    );
    e_LF.name = "LF";
    this.facesGroup.add(e_LF);

    // Bottom
    const e_DR = faces.genEdge(
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(0.5, 0.35, -0.35),
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.35, -0.35, -0.5)
    );
    e_DR.name = "DR";
    this.facesGroup.add(e_DR);

    const e_DL = faces.genEdge(
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(-0.5, -0.35, -0.35),
      new THREE.Vector3(-0.5, 0.35, -0.35),
      new THREE.Vector3(-0.35, 0.35, -0.5)
    );
    e_DL.name = "DL";
    this.facesGroup.add(e_DL);

    const e_DB = faces.genEdge(
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(0.35, 0.5, -0.35),
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(-0.35, 0.35, -0.5)
    );
    e_DB.name = "DB";
    this.facesGroup.add(e_DB);

    const e_DF = faces.genEdge(
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(0.35, -0.35, -0.5),
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(-0.35, -0.5, -0.35)
    );
    e_DF.name = "DF";
    this.facesGroup.add(e_DF);

    // ViewCube Outline
    const outline = faces.genOutline();
    this.outlineGroup.add(outline);

    this.add(this.outlineGroup);
    this.add(this.facesGroup);

    this.width = 150; // in px
    this.margin = 10; // in px

    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1);
    this.camera.position.copy(new THREE.Vector3(0, 0, 0));
    this.camera.lookAt(new THREE.Vector3(0, 1, 0));
    this.camera.updateMatrixWorld();
    this.camera.rotation.order = "ZXY";

    this.hoveredFace = null;
    this.raycaster = new THREE.Raycaster();

    const onMouseDown = event => {
      this.pickedFace = this.hoveredFace;

      if (this.pickedFace) {
        this.viewer.setView(this.pickedFace);
      }
    };

    const onMouseMove = event => {
      let mouse = new THREE.Vector2();
      mouse.x = event.clientX - (window.innerWidth - this.width + this.margin);
      mouse.y = event.clientY + this.margin;

      if (mouse.x < 0 || mouse.y > this.width) {
        if (this.hoveredFace || this.hoveredFace === "") {
          this.facesGroup.children.forEach(
            child => (child.material.opacity = STANDBY_OPACITY)
          );
          this.hoveredFace = null;
        }
        return;
      }

      mouse.x = (mouse.x / this.width) * 2 - 1;
      mouse.y = -(mouse.y / this.width) * 2 + 1;

      this.raycaster.setFromCamera(mouse, this.camera);
      this.raycaster.ray.origin.sub(
        this.camera.getWorldDirection(new THREE.Vector3())
      );

      let intersects = this.raycaster.intersectObjects(
        this.facesGroup.children
      );

      if (intersects.length > 0) {
        if (this.hoveredFace !== intersects[0].object.name) {
          this.facesGroup.children.forEach(
            child => (child.material.opacity = DEFAULT_OPACITY)
          );
          this.hoveredFace = intersects[0].object.name;
          intersects[0].object.material.opacity = HOVERED_OPACITY;
        }
      } else {
        if (this.hoveredFace || this.hoveredFace === "") {
          this.facesGroup.children.forEach(
            child => (child.material.opacity = STANDBY_OPACITY)
          );
          this.hoveredFace = null;
        }
      }
    };

    this.viewer.renderer.domElement.addEventListener(
      "mousedown",
      onMouseDown,
      false
    );
    this.viewer.renderer.domElement.addEventListener(
      "mousemove",
      onMouseMove,
      false
    );
  }

  update(rotation) {
    this.camera.rotation.copy(rotation);
    this.camera.updateMatrixWorld();
  }
}

// Viewcube Factory
const faceFactory = function(size, color) {
  var scope = this;

  this.genFace = function(position, rotation, img) {
    var geo = new THREE.PlaneBufferGeometry(size * 0.7, size * 0.7);
    var midpoint = position.multiplyScalar(size);
    var mat = new THREE.MeshBasicMaterial({
      color: color,
      map: new THREE.TextureLoader().load(
        exports.resourcePath + "/textures/navigation/" + img
      ),
      transparent: true,
      opacity: STANDBY_OPACITY,
    });
    var face = new THREE.Mesh(geo, mat);
    face.position.set(midpoint.x, midpoint.y, midpoint.z);
    face.rotation.set(rotation.x, rotation.y, rotation.z);
    face.updateMatrixWorld();
    return face;
  };

  this.genCorner = function(p1, p2, p3) {
    var v1 = p1.multiplyScalar(size);
    var v2 = p2.multiplyScalar(size);
    var v3 = p3.multiplyScalar(size);

    var geo = new THREE.BufferGeometry();
    var verts = new Float32Array([
      v1.x,
      v1.y,
      v1.z,
      v2.x,
      v2.y,
      v2.z,
      v3.x,
      v3.y,
      v3.z,
    ]);
    geo.setAttribute("position", new THREE.BufferAttribute(verts, 3));
    geo.computeVertexNormals();
    geo.computeFaceNormals();
    var mat = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: STANDBY_OPACITY,
    });
    return new THREE.Mesh(geo, mat);
  };

  this.genEdge = function(p1, p2, p3, p4) {
    var v1 = p1.multiplyScalar(size);
    var v2 = p2.multiplyScalar(size);
    var v3 = p3.multiplyScalar(size);
    var v4 = p4.multiplyScalar(size);

    var geo = new THREE.BufferGeometry();
    var verts = new Float32Array([
      v1.x,
      v1.y,
      v1.z,
      v2.x,
      v2.y,
      v2.z,
      v3.x,
      v3.y,
      v3.z,

      v1.x,
      v1.y,
      v1.z,
      v3.x,
      v3.y,
      v3.z,
      v4.x,
      v4.y,
      v4.z,
    ]);
    geo.setAttribute("position", new THREE.BufferAttribute(verts, 3));
    geo.computeVertexNormals();
    geo.computeFaceNormals();
    var mat = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: STANDBY_OPACITY,
    });
    return new THREE.Mesh(geo, mat);
  };

  this.genOutline = function() {
    var geo = new THREE.Geometry();
    geo.vertices.push(
      // Along +Y
      new THREE.Vector3(-0.5, 0.35, 0.35),
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(0.35, 0.35, 0.5),
      new THREE.Vector3(0.35, 0.35, 0.5),
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.5, 0.35, -0.35),
      new THREE.Vector3(0.5, 0.35, -0.35),
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(-0.35, 0.35, -0.5),
      new THREE.Vector3(-0.35, 0.35, -0.5),
      new THREE.Vector3(-0.5, 0.35, -0.35),
      new THREE.Vector3(-0.5, 0.35, -0.35),
      new THREE.Vector3(-0.5, 0.35, 0.35),

      // Along -Y
      new THREE.Vector3(-0.5, -0.35, 0.35),
      new THREE.Vector3(-0.35, -0.35, 0.5),
      new THREE.Vector3(-0.35, -0.35, 0.5),
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(0.5, -0.35, 0.35),
      new THREE.Vector3(0.5, -0.35, 0.35),
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.35, -0.35, -0.5),
      new THREE.Vector3(0.35, -0.35, -0.5),
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(-0.5, -0.35, -0.35),
      new THREE.Vector3(-0.5, -0.35, -0.35),
      new THREE.Vector3(-0.5, -0.35, 0.35),

      // Along +Z
      new THREE.Vector3(-0.5, 0.35, 0.35),
      new THREE.Vector3(-0.35, 0.5, 0.35),
      new THREE.Vector3(-0.35, 0.5, 0.35),
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.5, 0.35, 0.35),
      new THREE.Vector3(0.5, -0.35, 0.35),
      new THREE.Vector3(0.5, -0.35, 0.35),
      new THREE.Vector3(0.35, -0.5, 0.35),
      new THREE.Vector3(0.35, -0.5, 0.35),
      new THREE.Vector3(-0.35, -0.5, 0.35),
      new THREE.Vector3(-0.35, -0.5, 0.35),
      new THREE.Vector3(-0.5, -0.35, 0.35),
      new THREE.Vector3(-0.5, -0.35, 0.35),
      new THREE.Vector3(-0.5, 0.35, 0.35),

      // Along -Z
      new THREE.Vector3(-0.5, 0.35, -0.35),
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(0.35, 0.5, -0.35),
      new THREE.Vector3(0.35, 0.5, -0.35),
      new THREE.Vector3(0.5, 0.35, -0.35),
      new THREE.Vector3(0.5, 0.35, -0.35),
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.5, -0.35, -0.35),
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(-0.35, -0.5, -0.35),
      new THREE.Vector3(-0.35, -0.5, -0.35),
      new THREE.Vector3(-0.5, -0.35, -0.35),
      new THREE.Vector3(-0.5, -0.35, -0.35),
      new THREE.Vector3(-0.5, 0.35, -0.35),

      // Along +X
      new THREE.Vector3(0.35, -0.5, 0.35),
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(0.35, -0.35, 0.5),
      new THREE.Vector3(0.35, 0.35, 0.5),
      new THREE.Vector3(0.35, 0.35, 0.5),
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(0.35, 0.5, 0.35),
      new THREE.Vector3(0.35, 0.5, -0.35),
      new THREE.Vector3(0.35, 0.5, -0.35),
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(0.35, 0.35, -0.5),
      new THREE.Vector3(0.35, -0.35, -0.5),
      new THREE.Vector3(0.35, -0.35, -0.5),
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(0.35, -0.5, -0.35),
      new THREE.Vector3(0.35, -0.5, 0.35),

      // Along -X
      new THREE.Vector3(-0.35, -0.5, 0.35),
      new THREE.Vector3(-0.35, -0.35, 0.5),
      new THREE.Vector3(-0.35, -0.35, 0.5),
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(-0.35, 0.35, 0.5),
      new THREE.Vector3(-0.35, 0.5, 0.35),
      new THREE.Vector3(-0.35, 0.5, 0.35),
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(-0.35, 0.5, -0.35),
      new THREE.Vector3(-0.35, 0.35, -0.5),
      new THREE.Vector3(-0.35, 0.35, -0.5),
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(-0.35, -0.35, -0.5),
      new THREE.Vector3(-0.35, -0.5, -0.35),
      new THREE.Vector3(-0.35, -0.5, -0.35),
      new THREE.Vector3(-0.35, -0.5, 0.35)
    );
    for (var i = 0, j = geo.vertices.length; i < j; i++) {
      geo.vertices[i].multiplyScalar(size);
    }
    var mat = new THREE.LineBasicMaterial({
      color: OUTLINE_COLOR,
    });
    return new THREE.LineSegments(geo, mat);
  };
};
faceFactory.prototype.constructor = faceFactory;
